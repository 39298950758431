<template>
  <div class="SKU">
    <div class="SKU-main">
      <div class="search-box">
        <el-input
          placeholder="请输入英文品牌关键字或SKU进行搜索"
          v-model="input"
          class="input-with-select"
        >
          <el-select
            v-model="select"
            slot="prepend"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(item, i) in wayPath"
              :key="wayPath[i].id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-input>
        <i class="el-icon-search search-btn" @click="search"></i>
      </div>
      <div class="table-div">
        <el-table
          :data="goodsList"
          style="width: 100%"
          border
          v-loading="loading"
          empty-text="暂无数据"
        >
          <el-table-column prop="sku" label="sku" width="119">
          </el-table-column>
          <el-table-column prop="type" label="分类" width="129">
          </el-table-column>
          <el-table-column prop="englishBrand" label="品牌" width="119">
          </el-table-column>
          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column prop="englishName" label="英文名称">
          </el-table-column>
          <el-table-column prop="price" label="单价" width="94">
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-div">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="true"
          :page-count="pageCount"
          :current-page.sync="currentPage"
          @current-change="currentPageChanged"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getAllServiceLine, showGoodsListToUser } from "@/api/order/order";
export default {
  name: "SKU",
  data() {
    return {
      // 文本框的值
      input: "",
      // 下拉框的值
      select: "",
      // 表格值
      goodsList: [],
      wayPath: [],
      // 分页总条数
      pageCount: 0,
      // 当前页码
      currentPage: 1,
      // 表格loading
      loading: false,
    };
  },
  created() {
    this.getAllServiceLine();
    this.getList();
  },
  methods: {
    // 搜索
    search() {
      this.getList();
    },
    getList() {
      let upData = {
        pageNum: this.currentPage,
        pageSize: "20",
        // enGoodsName:this.input,
        content: this.input,
        lineId: this.select,
      };
      this.loading = true;
      showGoodsListToUser(upData)
        .then((res) => {
          if (res.data.code === 0) {
            this.pageCount = res.data.data.page.totalPage;
            this.goodsList = res.data.data.page.list;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取服务路线分类
    getAllServiceLine() {
      getAllServiceLine().then((res) => {
        if (res.data.code === 0) {
          this.wayPath = res.data.data.list;
        }
      });
    },
    // 切换页码
    currentPageChanged(arg) {
      console.log("切换了页码", arg);
      this.currentPage = arg;
      //请求数据
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.SKU {
  background: #f4f4f4;
  width: 100%;
  min-height: 100%;
  padding-top: 20px;
  .SKU-main {
    background: #fff;
    width: 1000px;
    margin: 0 auto;
    height: 100%;
    border-radius: 8px;
    padding: 24px;
    .input-with-select {
      width: 567px;
      &::v-deep .el-input-group__prepend {
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
        width: 192px;
        background: #fff;
      }
      ::v-deep .el-input__inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &::v-deep .el-input-group__prepend .el-input__inner {
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .search-box {
    position: relative;
    width: 567px;
    margin-bottom: 24px;
    .search-btn {
      position: absolute;
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      cursor: pointer;
      &:hover {
        color: #6d5efe;
      }
    }
  }
}
</style>
